import { API_URL } from "./env"

export const urlFiles = {
  productImage: `${API_URL}/foto-producto`,
  productFile: `${API_URL}/archivo-producto`,
}

export const urlPdfs = {
  // pdfs a4  
  ventaA4: `${API_URL}/api/pdf/a4/venta`,
  reporteVentasA4: `${API_URL}/api/pdf/a4/reporte-ventas`,
  notaCreditoA4: `${API_URL}/api/pdf/a4/nota-credito`,
  proformaA4: `${API_URL}/api/pdf/a4/proforma`,
  guiaA4: `${API_URL}/api/pdf/a4/guia-remision`,
  entradaA4: `${API_URL}/api/pdf/a4/entrada`,
  salidaA4: `${API_URL}/api/pdf/a4/salida`,
  cierreCajaA4: `${API_URL}/api/pdf/a4/cierre-caja`,
  ordenServicioA4: `${API_URL}/api/pdf/a4/orden-servicio`,
  reclamacionA4: `${API_URL}/api/pdf/a4/reclamacion`,
  inventarioOrdenServicioA4: `${API_URL}/api/pdf/a4/inventario-orden-servicio`,
  reportePrestamosClienteA4: `${API_URL}/api/pdf/a4/reporte-prestamos-cliente`,

  // pdfs ticket
  ventaTicket: `${API_URL}/api/pdf/ticket/venta`,
  proformaTicket: `${API_URL}/api/pdf/ticket/proforma`,
  pedidoTicket: `${API_URL}/api/pdf/ticket/pedido`,
  cajaChicaTicket: `${API_URL}/api/pdf/ticket/caja-chica`,
  notaPagoTicket: `${API_URL}/api/pdf/ticket/nota-pago`,
  prestamoTicket: `${API_URL}/api/pdf/ticket/prestamo`,
  prestamoCajaChicaTicket: `${API_URL}/api/pdf/ticket/prestamo-caja-chica`,
  pagoCuotaPrestamoTicket: `${API_URL}/api/pdf/ticket/pago-cuota-prestamo`,
}

export const urlExcels = {
  reporteVentas: `${API_URL}/api/excel/reporte-ventas`,
}