import React from "react"
import './app-loading.css'

const AppLoading = () => {
  return (
    <div className="content-app-loader">
      <div className="app-loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default AppLoading