import * as Actions from "../../actions/app"

const initialState = {
  loading_crud_product_request: false,
  crud_product_request: null,

  loading_product_requests: false,
  product_requests: null,
  total_product_requests: 0,
}

export default function productRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.CRUD_PRODUCT_REQUEST:
      return {
        ...state,
        loading_crud_product_request: action.payload.loading,
        crud_product_request: action.payload.crud,
      }

    case Actions.GET_PRODUCT_REQUESTS:
      return {
        ...state,
        loading_product_requests: action.payload.loading,
        product_requests: action.payload.product_requests ?? state.product_requests,
        total_product_requests: action.payload.total_product_requests ?? state.total_product_requests,
      }

    default:
      return state
  }
}
