import axios from "axios"
import history from '@history';
import authService from 'services/authService';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

export function setUserData(user) {
    return (dispatch) => dispatch({
        type: SET_USER_DATA,
        payload: user
    });
}

export function removeUserData() {
    return {
        type: REMOVE_USER_DATA
    };
}
export function redirecDashboard() {
    return (dispatch, getState) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}`
        });
    }
}
export function logoutUser() {
    return (dispatch, getState) => {
        history.entries = [];
        history.index = -1;
        history.push({
            pathname: `${process.env.PUBLIC_URL}`
        });

        authService.logout();

        dispatch({
            type: USER_LOGGED_OUT
        })
    }
}
export function handleRemoveUserToken(response) {
    if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
            localStorage.removeItem("access_token")
            delete axios.defaults.headers.common["Authorization"]
            logoutUser()
            throw new Error("Su sesión ha expirado.")
        }
        return
    }
    return
}
