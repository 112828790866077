import axios from "axios"
import { v4 as uuid } from "uuid"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const CRUD_PRODUCT_REQUEST = "[PRODUCT_REQUESTS] CRUD PRODUCT REQUEST"

export const GET_PRODUCT_REQUESTS = "[PRODUCT_REQUESTS] GET PRODUCT REQUESTS"

export function saveProductRequest(form) {
  const request = axios.post(`${API_URL}/api/requerimiento-producto/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) === 400) {
        toast.error(response.data.message)
        return dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: null } })
      }
      dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: uuid() } })
      toast.success(response.data.message)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: null } })
      toast.error("Error inesperado, no se pudo agregar el producto a tus requerimientos")
      console.log(CRUD_PRODUCT_REQUEST, err)
    })
  }
}

export function deleteProductRequest(id) {
  const request = axios.delete(`${API_URL}/api/requerimiento-producto/eliminar`, { params: { id } })
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) === 400) {
        toast.error(response.data.message)
        dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: null } })
        return
      }
      dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: uuid() } })
      toast.success(response.data.message)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT_REQUEST, payload: { loading: false, crud: null } })
      toast.error("Error inesperado, no se pudo eliminar el producto de tus requerimientos")
      console.log(CRUD_PRODUCT_REQUEST, err)
    })
  }
}

export function getProductRequests({ page = 1, pageSize = 10 }) {
  const request = axios.get(`${API_URL}/api/requerimiento-producto/registros`, { params: { page, pageSize } })
  return (dispatch) => {
    dispatch({ type: GET_PRODUCT_REQUESTS, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({
        type: GET_PRODUCT_REQUESTS,
        payload: {
          loading: false,
          product_requests: pageSize == 0
            ? response.data
            : response.data.data,
          total_product_requests: pageSize == 0
            ? response.data?.length
            : response.data.total
        }
      })
    }).catch((err) => {
      dispatch({ type: GET_PRODUCT_REQUESTS, payload: { loading: false } })
      toast.error("Error inesperado, no se pudo obtener los requerimientos")
      console.log(GET_PRODUCT_REQUESTS, err)
    })
  }
}
