import React from "react"
import { AuthRoles } from "components/auth"

export const ValuedInventoryReportConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/valued_inventory_report`,
      component: React.lazy(() => import("./ValuedInventoryReport")),
    },
  ],
}
