import axios from "axios"
import { v4 as uuid } from "uuid"
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'
import { downloadBase64File } from 'utils'
import { urlPdfs, API_URL, FILE_TYPE } from 'constants/index'
import { sendCreditNote } from "./manage_cpe.actions"

export const GET_LIST_DISCREPANCIES = "[CREDIT_NOTE] GET LIST DISCREPANCIES"

export const GET_CREDIT_NOTES = "[CREDIT_NOTE] GET LIST CREDIT NOTE"

export const DOWNLOADING_CREDIT_NOTES_ACCOUNTING_REPORT = "[SALES] DOWNLOADING SALES ACCOUNTING REPORT"

export const CRUD_CREDIT_NOTE = "[CREDIT_NOTE] SAVE CREDIT NOTE"

export function getDiscrepancies() {
  const request = axios.get(`${API_URL}/api/tdiscrepancias`)
  return dispatch =>
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_LIST_DISCREPANCIES, payload: response.data.detalles })
    })
}

export function getCreditNotes({
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  status = '',
  search = '',
  skipBranch = false,
  skipUser = false
}) {
  const request = axios.get(`${API_URL}/api/nota-credito/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
      omitir_sucursal: skipBranch,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_CREDIT_NOTES, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_CREDIT_NOTES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_CREDIT_NOTES, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function downloadCreditNotesAccountingReportPdf({
  startDate = '',
  endDate = '',
  status = '',
  search = '',
}) {
  const request = axios.get(`${API_URL}/api/nota-credito/pdf-reporte-contable-notas-credito`, {
    params: {
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_CREDIT_NOTES_ACCOUNTING_REPORT, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.PDF,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_CREDIT_NOTES_ACCOUNTING_REPORT, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_CREDIT_NOTES_ACCOUNTING_REPORT, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function saveCreditNote(form) {
  const request = axios.post(`${API_URL}/api/nota-credito/registrar-nota-credito`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CREDIT_NOTE, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) === 400) {
        toast.error(response.data.message)
        return dispatch({ type: CRUD_CREDIT_NOTE, payload: { loading: false, crud: null } })
      }
      sendCreditNote({ idnota: response.data.document_id, refresh: false })
      dispatch({ type: CRUD_CREDIT_NOTE, payload: { loading: false, crud: uuid() } })
      toast.success(response.data.message)
      window.open(`${urlPdfs.notaCreditoA4}/${response.data.document_id}`, "_blank")
    }).catch((err) => {
      dispatch({ type: CRUD_CREDIT_NOTE, payload: { loading: false, crud: null } })
      toast.error("Error inesperado, no se pudo registrar la nota de crédito")
      console.log(CRUD_CREDIT_NOTE, err)
    })
  }
}