import * as Actions from '../../actions/app'

const initialState = {
  loading_init_store_products_in_kardex: false,
  finished_init_store_products_in_kardex: null,

  loading_kardex_product: false,
  kardex_product: null,
  total_kardex_product: 0,
  kardex_summary: null,

  downloading_kardex_product_report: false,
}

const kardexReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.INIT_STORE_PRODUCTS_IN_KARDEX: {
      return {
        ...state,
        loading_init_store_products_in_kardex: action.payload.loading,
        finished_init_store_products_in_kardex: action.payload.finished,
      }
    }

    case Actions.GET_KARDEX_PRODUCT: {
      return {
        ...state,
        loading_kardex_product: action.payload.loading,
        kardex_product: action.payload.items ?? state.kardex_product,
        total_kardex_product: action.payload.total ?? state.total_kardex_product,
        kardex_summary: action.payload.summary ?? state.kardex_summary,
      }
    }

    case Actions.DOWNLOADING_KARDEX_PRODUCT_REPORT: {
      return {
        ...state,
        downloading_kardex_product_report: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default kardexReducer
