import React from "react"
import AppLoading from "./AppLoading"

const AppSuspense = ({ children }) => {
  return (
    <React.Suspense fallback={<AppLoading />}>
      {children}
    </React.Suspense >
  )
}

export default AppSuspense