/*
    Configuración: routesConfig
    Descripción: Archivo de configuración de las rutas de la aplicación
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import AppUtils from '@app/AppUtils';

import { LoginConfig } from 'pages/login/LoginConfig';
import { ProductsConfig } from 'pages/products/ProductsConfig';
import { DashboardConfig } from 'pages/dashboard/DashboardConfig';
import { PeopleConfig } from 'pages/people/PeopleConfig';
import { VouchersConfig } from 'pages/vouchers/VouchersConfig';
import { EstablishmentConfig } from 'pages/establishment/EstablishmentConfig';
import { UsersConfig } from 'pages/users/UsersConfig';
import { SalesConfig } from 'pages/sales/SalesConfig';
import { OrdersConfig } from 'pages/orders/OrdersConfig';
import { ProformasConfig } from 'pages/proformas/ProformasConfig';
import { ManageCashConfig } from 'pages/manage_cash/ManageCashConfig';
import { AccessControlConfig } from 'pages/access_control/AccessControlConfig';
import { CashReceiptsConfig } from 'pages/cash_receipts/CashReceiptsConfig';
import { CashOutlaysConfig } from 'pages/cash_outlays/CashOutlaysConfig';
import { ManageCpeConfig } from 'pages/manage_cpe/ManageCpeConfig';
import { CreditNotesConfig } from 'pages/credit_notes/CreditNotesConfig';
import { ElectronicGuidesConfig } from 'pages/electronic_guide/ElectronicGuidesConfig';
import { MenuControlConfig } from 'pages/menu_control/MenuControlConfig';
import { ManagementDriverConfig } from 'pages/management_driver/ManagementDriverConfig';
import { VehiclesConfig } from 'pages/vehicles/VehiclesConfig';
import { VehicleRequirementsConfig } from 'pages/vehicle_requirements/VehicleRequirementsConfig';
import { VehicleOrderServicesConfig } from 'pages/vehicle_service_orders/VehicleOrderServicesConfig';
import { EmployeesConfig } from 'pages/employees/EmployeesConfig';
import { AttentionServiceOrdersConfig } from 'pages/attention_service_orders/AttentionServiceOrdersConfig';
import { VehicleStationsConfig } from 'pages/vehicle_stations/VehicleStationsConfig';
import { DispatchesConfig } from 'pages/dispatches/DispatchesConfig';
import { EntriesConfig } from 'pages/entries/EntriesConfig';
import { OutputsConfig } from 'pages/outputs/OutputsConfig';
import { ProductPricesConfig } from 'pages/product_prices/ProductPricesConfig';
import { OperatingExpensesConfig } from 'pages/operating_expenses/OperatingExpensesConfig';
import { LoansConfig } from 'pages/loans/LoansConfig';
import { StoresConfig } from 'pages/stores/StoresConfig';
import { ComplaintsBookConfig } from 'pages/complaints_book/ComplaintsBookConfig';
import { EstablishmentsComplaintsConfig } from 'pages/establishments_complaints/EstablishmentsComplaintsConfig';
import { TransfersConfig } from 'pages/transfers/TransfersConfig';
import { LoansOpeningConfig } from 'pages/loans_opening/LoansOpeningConfig';
import { LoanOpeningIncomesConfig } from 'pages/loan_opening_incomes/LoanOpeningIncomesConfig';
import { LoanOpeningExpensesConfig } from 'pages/loan_opening_expenses/LoanOpeningExpensesConfig';
import { LoansReportConfig } from 'pages/loans_report/LoansReportConfig';
import { InventoryConfig } from 'pages/inventory/InventoryConfig';
import { ProductRequestsConfig } from 'pages/product_requests/ProductRequestsConfig';
import { SalesAccountingReportConfig } from 'pages/sales_accounting_report/SalesAccountingReportConfig';
import { PurchasesConfig } from 'pages/purchases/PurchasesConfig';
import { CustomerCreditsConfig } from 'pages/customer_credits/CustomerCreditsConfig';
import { ProviderCreditsConfig } from 'pages/provider_credits/ProviderCreditsConfig';
import { SalesBySellerConfig } from 'pages/sales_by_seller/SalesBySellerConfig';
import { PurchasesReportConfig } from 'pages/purchases_report/PurchasesReportConfig';
import { ValuedInventoryReportConfig } from 'pages/valued_inventory_report/ValuedInventoryReportConfig';
import { KardexConfig } from 'pages/kardex/KardexConfig';

const routesConfig = [
  LoginConfig,
  ProductsConfig,
  DashboardConfig,
  PeopleConfig,
  VouchersConfig,
  EstablishmentConfig,
  UsersConfig,
  SalesConfig,
  OrdersConfig,
  ProformasConfig,
  ManageCashConfig,
  AccessControlConfig,
  CashReceiptsConfig,
  CashOutlaysConfig,
  ManageCpeConfig,
  CreditNotesConfig,
  ElectronicGuidesConfig,
  MenuControlConfig,
  ManagementDriverConfig,
  VehiclesConfig,
  EmployeesConfig,
  VehicleRequirementsConfig,
  VehicleOrderServicesConfig,
  AttentionServiceOrdersConfig,
  VehicleStationsConfig,
  DispatchesConfig,
  EntriesConfig,
  OutputsConfig,
  ProductPricesConfig,
  OperatingExpensesConfig,
  LoansConfig,
  StoresConfig,
  ComplaintsBookConfig,
  EstablishmentsComplaintsConfig,
  TransfersConfig,
  LoansOpeningConfig,
  LoanOpeningIncomesConfig,
  LoanOpeningExpensesConfig,
  LoansReportConfig,
  InventoryConfig,
  ProductRequestsConfig,
  SalesAccountingReportConfig,
  PurchasesConfig,
  CustomerCreditsConfig,
  ProviderCreditsConfig,
  SalesBySellerConfig,
  PurchasesReportConfig,
  ValuedInventoryReportConfig,
  KardexConfig,
];

const routes = [
  ...AppUtils.generateRoutesFromConfigs(routesConfig, null),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
  {
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
];

export default routes;
