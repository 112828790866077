import axios from "axios"
import { v4 as uuid } from "uuid"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL, ORDER_REQUEST_FILTER_STATUS, ORDER_REQUEST_TYPE } from 'constants/index'

export const CRUD_PRODUCT_REQUEST_ORDER = "[PRODUCT_REQUEST_ORDERS] CRUD PRODUCT REQUEST ORDER"

export const GET_PRODUCT_REQUEST_ORDERS = "[PRODUCT_REQUEST_ORDERS] GET PRODUCT REQUEST ORDERS"

export function saveProductRequestOrder(form) {
  const request = axios.post(`${API_URL}/api/orden-requerimiento/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) === 400) {
        toast.error(response.data.message)
        return dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: null } })
      }
      dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: uuid() } })
      toast.success(response.data.message)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: null } })
      toast.error("Error inesperado, no se pudo guardar la orden de requerimiento")
      console.log(CRUD_PRODUCT_REQUEST_ORDER, err)
    })
  }
}

export function deleteProductRequestOrder(id) {
  const request = axios.delete(`${API_URL}/api/orden-requerimiento/eliminar`, { params: { id } })
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) === 400) {
        toast.error(response.data.message)
        dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: null } })
        return
      }
      dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: uuid() } })
      toast.success(response.data.message)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT_REQUEST_ORDER, payload: { loading: false, crud: null } })
      toast.error("Error inesperado, no se pudo eliminar la orden de requerimiento")
      console.log(CRUD_PRODUCT_REQUEST_ORDER, err)
    })
  }
}

export function getProductRequestOrders({
  page = 1,
  pageSize = 10,
  orderRequestType = ORDER_REQUEST_TYPE.PROVIDER_REQUEST,
  status = ORDER_REQUEST_FILTER_STATUS.all.value,
  search = ''
}) {
  const request = axios.get(`${API_URL}/api/orden-requerimiento/registros`, { params: { page, pageSize, orderRequestType, status, search } })
  return (dispatch) => {
    dispatch({ type: GET_PRODUCT_REQUEST_ORDERS, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      console.log(response.data)
      dispatch({
        type: GET_PRODUCT_REQUEST_ORDERS,
        payload: {
          loading: false,
          product_request_orders: response.data.data,
          total_product_request_orders: response.data.total
        }
      })
    }).catch((err) => {
      dispatch({ type: GET_PRODUCT_REQUEST_ORDERS, payload: { loading: false } })
      toast.error("Error inesperado, no se pudo obtener las ordenes de requerimientos")
      console.log(GET_PRODUCT_REQUEST_ORDERS, err)
    })
  }
}