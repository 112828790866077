export const ORDER_REQUEST_TYPE = {
  PROVIDER_REQUEST: 1,
  TRANSFER_REQUEST: 2,
}

export const ORDER_REQUEST_STATUS = {
  PENDING: 1,
  FINISHED: 0,
  DELETED: -1,
}

export const ORDER_REQUEST_TYPES = [
  {
    value: ORDER_REQUEST_TYPE.PROVIDER_REQUEST,
    label: 'Solicitud a proveedor',
  },
  {
    value: ORDER_REQUEST_TYPE.TRANSFER_REQUEST,
    label: 'Solicitud de traslado',
  },
]

export const ORDER_REQUEST_STATUS_LABELS = [
  {
    value: ORDER_REQUEST_STATUS.PENDING,
    label: 'Orden pendiente',
    color: 'orange'
  },
  {
    value: ORDER_REQUEST_STATUS.FINISHED,
    label: 'Orden finalizada',
    color: 'green'
  },
  {
    value: ORDER_REQUEST_STATUS.DELETED,
    label: 'Orden anulada',
    color: 'red'
  }
]

export const ORDER_REQUEST_FILTER_TYPES = {
  all: {
    value: '',
    label: 'Todos los tipos'
  },
  providerRequest: {
    value: ORDER_REQUEST_TYPE.PROVIDER_REQUEST,
    label: 'Solicitudes a proveedores'
  },
  transferRequest: {
    value: ORDER_REQUEST_TYPE.TRANSFER_REQUEST,
    label: 'Solicitudes de traslado'
  }
}

export const ORDER_REQUEST_FILTER_STATUS = {
  all: {
    value: 'todos',
    label: 'Todos los estados'
  },
  pending: {
    value: 'pendientes',
    label: 'Ordenes pendientes'
  },
  finished: {
    value: 'finalizadas',
    label: 'Ordenes finalizadas'
  },
  deleted: {
    value: 'anuladas',
    label: 'Ordenes anuladas'
  }
}