import * as Actions from "../../actions/app"

const initialState = {
  list_discrepancies: null,

  loading_credit_notes: false,
  credit_notes: null,
  total_credit_notes: 0,

  downloading_credit_notes_accounting_report: false,

  loading_crud_credit_note: false,
  crud_credit_note: null,
}

const creditNote = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LIST_DISCREPANCIES: {
      return {
        ...state,
        list_discrepancies: action.payload
      }
    }

    case Actions.GET_CREDIT_NOTES: {
      return {
        ...state,
        loading_credit_notes: action.payload.loading,
        credit_notes: typeof action.payload.detalles === 'undefined'
          ? state.credit_notes
          : action.payload.detalles,
        total_credit_notes: typeof action.payload.totalRegistros === 'undefined'
          ? state.total_credit_notes
          : action.payload.totalRegistros
      }
    }

    case Actions.DOWNLOADING_CREDIT_NOTES_ACCOUNTING_REPORT: {
      return {
        ...state,
        downloading_credit_notes_accounting_report: action.payload
      }
    }

    case Actions.CRUD_CREDIT_NOTE: {
      return {
        ...state,
        loading_crud_credit_note: action.payload.loading,
        crud_credit_note: action.payload.crud
      }
    }

    default: {
      return state
    }
  }
}

export default creditNote
