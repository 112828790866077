import * as Actions from "../../actions/app"

const initialState = {
  loading_crud_product_request_order: false,
  crud_product_request_order: null,

  loading_product_request_orders: false,
  product_request_orders: null,
  total_product_request_orders: 0,
}

export default function productRequestOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.CRUD_PRODUCT_REQUEST_ORDER:
      return {
        ...state,
        loading_crud_product_request_order: action.payload.loading,
        crud_product_request_order: action.payload.crud,
      }

    case Actions.GET_PRODUCT_REQUEST_ORDERS:
      return {
        ...state,
        loading_product_request_orders: action.payload.loading,
        product_request_orders: action.payload.product_request_orders ?? state.product_request_orders,
        total_product_request_orders: action.payload.total_product_request_orders ?? state.total_product_request_orders,
      }

    default:
      return state
  }
}
