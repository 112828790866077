import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// plantilla adminlte
import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/dist/js/adminlte.min.js'

// fontawesome
import '@fortawesome/fontawesome-free/css/all.min.css'

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

// react-bootstrap-typeahead
import 'react-bootstrap-typeahead/css/Typeahead.css'

// antd
import 'antd/dist/antd.css'
import './configs/antdConfig.js'

// react-toastify
import 'react-toastify/dist/ReactToastify.css'

// estilos propios
import './index.css'

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
)

serviceWorker.unregister()
