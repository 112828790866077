import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'
import { downloadBase64File } from 'utils'
import { API_URL, FILE_TYPE } from 'constants/index'

export const INIT_STORE_PRODUCTS_IN_KARDEX = '[KARDEX] INIT STORE PRODUCTS IN KARDEX'
export const GET_KARDEX_PRODUCT = '[KARDEX] GET KARDEX PRODUCT'
export const DOWNLOADING_KARDEX_PRODUCT_REPORT = '[KARDEX] DOWNLOADING KARDEX PRODUCT'

export function initStoreProductsInKardex() {
  const request = axios.post(`${API_URL}/api/kardex/init-store-products`)
  return (dispatch) => {
    dispatch({ type: INIT_STORE_PRODUCTS_IN_KARDEX, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: INIT_STORE_PRODUCTS_IN_KARDEX, payload: { loading: false, finished: uuid() } })
      toast.success(response.data.message)
    }).catch((err) => {
      dispatch({ type: INIT_STORE_PRODUCTS_IN_KARDEX, payload: { loading: false, finished: null } })
      toast.error("Error inesperado, no se pudo inicializar los productos de los almacenes en el kardex")
      console.log(INIT_STORE_PRODUCTS_IN_KARDEX, err)
    })
  }
}

export function getKardexProduct(params = {
  page: 0,
  productId: 0,
  storeId: 0,
  startDate: '',
  endDate: '',
  pageSize: 0,
  orderBy: 'desc'
}) {
  const request = axios.get(`${API_URL}/api/kardex/kardex-product`, { params })
  return (dispatch) => {
    dispatch({ type: GET_KARDEX_PRODUCT, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_KARDEX_PRODUCT, payload: { loading: false, ...response.data } })
    }).catch((err) => {
      dispatch({ type: GET_KARDEX_PRODUCT, payload: { loading: false } })
      toast.error("Error inesperado, no se pudo obtener el kardex del producto")
      console.log(GET_KARDEX_PRODUCT, err)
    })
  }
}

export function downloadKardexProductReportPdf(params = {
  productId: 0,
  storeId: 0,
  startDate: '',
  endDate: ''
}) {
  const request = axios.get(`${API_URL}/api/kardex/kardex-product-report-pdf`, { params })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_KARDEX_PRODUCT_REPORT, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.filename,
        fileType: FILE_TYPE.PDF,
        file: response.data.file
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_KARDEX_PRODUCT_REPORT, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_KARDEX_PRODUCT_REPORT, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}
