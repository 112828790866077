import { Select, AutoComplete, DatePicker, Table, Descriptions } from 'antd';

const { RangePicker } = DatePicker;

// props por defecto en Select
Select.defaultProps = {
  ...Select.defaultProps,
  dropdownMatchSelectWidth: false,
  placeholder: 'Seleccionar',
  notFoundContent: 'Sin opciones',
  getPopupContainer: (trigger) => trigger.parentNode,
};

// props por defecto en AutoComplete
AutoComplete.defaultProps = {
  ...AutoComplete.defaultProps,
  dropdownMatchSelectWidth: false,
  getPopupContainer: (trigger) => trigger.parentNode,
};

// props por defecto en RangePicker
RangePicker.defaultProps = {
  ...RangePicker.defaultProps,
  dropdownMatchSelectWidth: false,
  getPopupContainer: (trigger) => trigger.parentNode,
};

// props por defecto en Table
Table.defaultProps = {
  ...Table.defaultProps,
  pagination: {
    locale: {
      items_per_page: 'por página',
      jump_to: 'ir a',
      jump_to_confirm: 'confirmar',
      page: 'Página',
      prev_page: 'Página anterior',
      next_page: 'Página siguiente',
      prev_5: 'Anteriores 5 páginas',
      next_5: 'Siguientes 5 páginas',
      prev_3: 'Anteriores 3 páginas',
      next_3: 'Siguientes 3 páginas'
    }
  }
}

// props por defecto en Descriptions
Descriptions.defaultProps = {
  ...Descriptions.defaultProps,
  bordered: true,
  size: 'small',
  labelStyle: {
    fontWeight: 'bold',
    fontSize: '0.8rem'
  },
  contentStyle: {
    fontSize: '0.8rem'
  }
}
