import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_OPENINGS = "[MANAGE CASH] GET OPENINGS"

export const CRUD_OPENING = "[MANAGE CASH] CRUD OPENING"

export const GET_OPENING_INCOMES_AND_EXPENSES = "[MANAGE CASH] GET OPENING INCOMES AND EXPENSES"

export const GET_CREDIT_NOTES_BY_CASH_REGISTER = "[MANAGE CASH] GET CREDIT NOTES BY CASH REGISTER"

export function getOpenings({ page = 1, pageSize = 10, startDate = '', endDate = '', status = '', search = '', }) {
  const request = axios.get(`${API_URL}/api/caja/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_OPENINGS, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_OPENINGS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_OPENINGS, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function saveOpening(from) {
  const request = axios.post(`${API_URL}/api/caja/abrir-caja`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
      toast.success(response.data.message)
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo realizar la apertura de caja.")
      console.log(CRUD_OPENING, error)
    })
  }
}

export function getOpeningIncomesAndExpenses(openingId) {
  const request = axios.get(`${API_URL}/api/caja/ingresos-egresos/${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: true, data: null } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: false, data: response.data.data } })
    }).catch((error) => {
      console.log(GET_OPENING_INCOMES_AND_EXPENSES, error)
      dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: false, data: null } })
    })
  }
}

export function getCreditNotesByCashRegisterId(cashRegisterId) {
  const request = axios.get(`${API_URL}/api/caja/notas-credito/${cashRegisterId}`)
  return (dispatch) => {
    dispatch({ type: GET_CREDIT_NOTES_BY_CASH_REGISTER, payload: { loading: true, data: null } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_CREDIT_NOTES_BY_CASH_REGISTER, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      console.log(GET_CREDIT_NOTES_BY_CASH_REGISTER, error)

      dispatch({ type: GET_CREDIT_NOTES_BY_CASH_REGISTER, payload: { loading: false, data: null } })
    })
  }
}

export function closeOpening(from) {
  const request = axios.post(`${API_URL}/api/caja/cerrar-caja`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
      toast.success(response.data.message)
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo cerrar la caja.")
      console.log(CRUD_OPENING, error)
    })
  }
}

export function revertOpeningClosed(openingId) {
  const request = axios.post(`${API_URL}/api/caja/revertir-cierre-caja/${openingId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
      toast.success(response.data.message)
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo revertir el cierre de caja.")
      console.log(CRUD_OPENING, error)
    })
  }
}

export function deleteOpening(openingId) {
  const request = axios.post(`${API_URL}/api/caja/anular-caja/${openingId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
      toast.success(response.data.message)
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo anular la caja.")
      console.log(CRUD_OPENING, error)
    })
  }
}