export const PRODUCT_TYPES = {
  ARTICLE: 1,
  SERVICE: 2,
}

export const PRODUCT_TYPE_OPTIONS = [
  {
    value: PRODUCT_TYPES.ARTICLE,
    label: 'Artículo'
  },
  {
    value: PRODUCT_TYPES.SERVICE,
    label: 'Servicio'
  }

]
