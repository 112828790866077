import * as Actions from "../../actions/app"

const initialState = {

  loading_openings: false,
  openings: null,
  total_openings: 0,

  loading_crud_opening: false,
  crud_opening: false,

  loading_opening_incomes_and_expenses: false,
  opening_incomes_and_expenses: null,

  loading_credit_notes_by_cash_register: false,
  credit_notes_by_cash_register: null,
  total_amount_credit_notes_by_cash_register: 0,
}

const manageCashReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_OPENINGS: {
      return {
        ...state,
        loading_openings: action.payload.loading,
        openings: action.payload.items ?? state.openings,
        total_openings: action.payload.total ?? state.total_openings,
      }
    }

    case Actions.CRUD_OPENING: {
      return {
        ...state,
        loading_crud_opening: action.payload.loading,
        crud_opening: action.payload.crud,
      }
    }

    case Actions.GET_OPENING_INCOMES_AND_EXPENSES: {
      return {
        ...state,
        loading_opening_incomes_and_expenses: action.payload.loading,
        opening_incomes_and_expenses: action.payload.data
      }
    }

    case Actions.GET_CREDIT_NOTES_BY_CASH_REGISTER: {
      return {
        ...state,
        loading_credit_notes_by_cash_register: action.payload.loading,
        credit_notes_by_cash_register: action.payload.data,
        total_amount_credit_notes_by_cash_register: action.payload.total_amount
      }
    }


    default: {
      return state
    }
  }
}
export default manageCashReducer
