import React from 'react'
import { AuthRoles } from "components/auth"

export const KardexConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/kardex`,
      component: React.lazy(() => import('./Kardex'))
    }
  ]
}