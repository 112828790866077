import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'components/auth/store/actions';
import { bindActionCreators } from 'redux';
import authService from 'services/authService';
import AppLoading from '@app/components/AppLoading';

class Auth extends Component {
    state = {
        waitAuthCheck: true
    }

    componentDidMount() {

        return Promise.all([
            this.tokenCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false })
        })
    }

    tokenCheck = () => new Promise(resolve => {
        authService.on('onAutoLogin', () => {
            const pathname = !!this.props.location.pathname.substring(1).length
                ? this.props.location.pathname.substring(1)
                : undefined;

            authService.signInWithToken(pathname)
                .then(user => {
                    this.props.setUserData(user);
                    resolve();
                }).catch(error => {
                    resolve();
                })
        });

        authService.on('onAutoLogout', (message) => {
            this.props.logout();

            resolve();
        });

        authService.on('onNoAccessToken', () => {

            resolve();
        });

        authService.init();

        return Promise.resolve();
    })

    render() {
        return this.state.waitAuthCheck
            ? <AppLoading />
            : this.props.children
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: userActions.logoutUser,
        setUserData: userActions.setUserData,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
