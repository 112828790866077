import { combineReducers } from 'redux';

import products from './products.reducer';
import voucher from './voucher.reducer';
import establishment from './establishment.reducer';
import series from './series.reducer';
import users from './users.reducer';
import sales from './sales.reducer';
import orders from './order.reducer';
import proformas from './proformas.reducer';
import manageCash from './manage_cash.reducer';
import creditNote from './credit_note.reducer';
import manageCpe from './manage_cpe.reducer';
import electronicGuide from './electronic_guide.reducer';
import dashboard from './dashboard.reducer';
import accessControl from './access_control.reducer';
import menuControl from './menu_control.reducer';
import managementDriver from './management_driver.reducer';
import vehicles from './vehicles.reducer';
import vehicleRequirements from './vehicle_requirements.reducer';
import employees from './employees.reducer';
import vehicleServiceOrders from './vehicle_service_orders.reducer';
import vehiclesInventories from './vehicle_inventory.reducer';
import vehicleStations from './vehicle_stations.reducer';
import seriesProduct from './series_product.reducer';
import entriesAndOutputs from './entries_and_outputs.reducer';
import people from './people.reducer';
import cashMovements from './cash_movements.reducer';
import operatingExpenses from './operating_expenses.reducer';
import loansReducer from './loans.reducer';
import loansOpening from './loans_opening.reducer';
import loanOpeningMovements from './loan_opening_movements.reducer';
import stores from './stores.reducer';
import complaintsBook from './complaints_book.reducer';
import transfers from './transfers.reducer';
import inventories from './inventory.reducer';
import purchases from './purchases.reducer';
import customerCredits from './customer_credits.reducer'
import providerCredits from './provider_credits.reducer'
import providerCreditLetters from './provider_credit_letters.reducer'
import productRequests from './product_requests.reducer'
import productRequestOrders from './product_request_orders.reducer'
import kardex from './kardex.reducer'

const appReducers = combineReducers({
  accessControl,
  menuControl,
  products,
  voucher,
  establishment,
  series,
  users,
  sales,
  orders,
  proformas,
  manageCash,
  creditNote,
  manageCpe,
  electronicGuide,
  dashboard,
  managementDriver,
  employees,
  vehicles,
  vehicleRequirements,
  vehicleServiceOrders,
  vehiclesInventories,
  vehicleStations,
  seriesProduct,
  entriesAndOutputs,
  people,
  cashMovements,
  operatingExpenses,
  loansReducer,
  stores,
  complaintsBook,
  transfers,
  loansOpening,
  loanOpeningMovements,
  inventories,
  purchases,
  customerCredits,
  providerCredits,
  providerCreditLetters,
  productRequests,
  productRequestOrders,
  kardex
});

export default appReducers;
