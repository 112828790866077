import React from "react"
import AppContext from "../AppContext"
import { renderRoutes } from 'react-router-config'
import { AppSuspense } from "./components"

const AppLayout = () => {
  return (
    <AppContext.Consumer>
      {({ routes }) => (
        <AppSuspense>
          {renderRoutes(routes)}
        </AppSuspense>
      )}
    </AppContext.Consumer>
  )
}

export default AppLayout