import axios from 'axios'
import { toast } from 'react-toastify'
import { urlPdfs } from 'constants/index'
import * as Actions from 'components/auth/store/actions'
import { API_URL } from 'constants/index'

export const GET_PROFORMAS = '[PROFORMAS] GET PROFORMAS'

export const GET_PROFORMA = '[PROFORMAS] GET PROFORMA'

export const CRUD_PROFORMA_HEADER = '[PROFORMAS] CRUD PROFORMA HEADER'

export const CRUD_PROFORMA_ITEM = '[PROFORMAS] CRUD PROFORMA ITEM'

export const MOD_PROFORMA_ITEM_PRICE = '[PROFORMAS] MOD PROFORMA ITEM PRICE';

export const GET_PROFORMA_ITEMS = '[PROFORMAS] GET PROFORMA ITEMS'

export const CRUD_PROFORMA = '[PROFORMAS] CRUD PROFORMA'

export const FINISH_PROFORMA_DETAIL = '[PROFORMAS] FINISH PROFORMA DETAIL'

export function getProformas(
  { page = 1, pageSize = 10, startDate = '', endDate = '', status = '', search = '', skipUser = false }
) {
  const request = axios.get(`${API_URL}/api/proforma/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_PROFORMAS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PROFORMAS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_PROFORMAS, payload: { loading: false } })
      console.log(error)
    })
  }
}

export function getProforma({ proformaId }) {
  const request = axios.get(
    `${API_URL}/api/proforma/obtener-proforma?idproforma=${proformaId}`
  )
  return (dispatch) => {
    dispatch({ type: GET_PROFORMA, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        dispatch({
          type: GET_PROFORMA,
          payload: { loading: false, data: response.data.detalles },
        })
        setTimeout(() => {
          dispatch({
            type: GET_PROFORMA,
            payload: { loading: false, data: null },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFORMA,
          payload: { loading: false, data: null },
        })
        toast.error('Error inesperado, no se pudo obtener la proforma')
        console.log(error)
      })
  }
}

export function saveProformaHeader(form) {
  const request = axios.post(
    `${API_URL}/api/proforma/guardar-cabecera`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PROFORMA_HEADER, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_PROFORMA_HEADER,
            payload: {
              loading: false,
              crud: true,
              data: response.data.detalles,
            },
          })
          toast.success(response.data.mensaje)
        } else {
          toast.error(response.data.mensaje)
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PROFORMA_HEADER,
            payload: { loading: false, crud: false, data: null },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PROFORMA_HEADER,
          payload: { loading: false, crud: false, data: null },
        })
        toast.error('Error inesperado. No se pudo generar la proforma')
        console.log(error)
      })
  }
}

export function getProformaItems({ proformaId = 0 }) {
  const request = axios.get(`${API_URL}/api/proforma-detalle/items?idproforma=${proformaId}`)
  return (dispatch) => {
    dispatch({ type: GET_PROFORMA_ITEMS, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      dispatch({ type: GET_PROFORMA_ITEMS, payload: { loading: false, data: response.data.items } })
      setTimeout(() => {
        dispatch({ type: GET_PROFORMA_ITEMS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PROFORMA_ITEMS, payload: { loading: false, data: null } })
      toast.error('Error inesperado. No se pudo obtener los items de la proforma')
      console.log(error)
    })
  }
}

export function saveProformaItem(form) {
  const request = axios.post(`${API_URL}/api/proforma-detalle/guardar-item`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: true } })
      setTimeout(() => {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo actualizar la cantidad del item")
      console.log(CRUD_PROFORMA_ITEM, err)
    })
  }
}

export function updateProformaItemQuantity(form) {
  const request = axios.post(`${API_URL}/api/proforma-detalle/actualizar-cantidad`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: true, id: form.id } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: true } })
      setTimeout(() => {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo actualizar la cantidad del item")
      console.log(CRUD_PROFORMA_ITEM, err)
    })
  }
}

export function updateProformaItemPrice(form) {
  const request = axios.post(`${API_URL}/api/proforma-detalle/actualizar-precio`, form)
  return (dispatch) => {
    dispatch({ type: MOD_PROFORMA_ITEM_PRICE, payload: { loading: true, id: `${form.producto_id}-${form.unidad_medida_id}` } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: MOD_PROFORMA_ITEM_PRICE, payload: { loading: false, modified: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: MOD_PROFORMA_ITEM_PRICE, payload: { loading: false, modified: true } })
      setTimeout(() => {
        dispatch({ type: MOD_PROFORMA_ITEM_PRICE, payload: { loading: false, modified: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: MOD_PROFORMA_ITEM_PRICE, payload: { loading: false, modified: false } })
      toast.error("Error inesperado, no se pudo actualizar el precio del item")
      console.log(MOD_PROFORMA_ITEM_PRICE, err)
    })
  }
}

export function deleteProformaItem(form) {
  const request = axios.post(`${API_URL}/api/proforma-detalle/eliminar-item`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: true, id: form.id } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: true } })
      setTimeout(() => {
        dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PROFORMA_ITEM, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo eliminar el item")
      console.log(CRUD_PROFORMA_ITEM, err)
    })
  }
}

export function finishProformaDetail(form) {
  const request = axios.post(`${API_URL}/api/proforma-detalle/finalizar-items`, form)
  return (dispatch) => {
    dispatch({ type: FINISH_PROFORMA_DETAIL, payload: { loading: true } })
    request.then((response) => {
      Actions.handleRemoveUserToken(response)
      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: FINISH_PROFORMA_DETAIL, payload: { loading: false, crud: false } })
        toast.error(response.data.message)
        return
      }
      dispatch({ type: FINISH_PROFORMA_DETAIL, payload: { loading: false, crud: true } })
      window.open(`${urlPdfs.proformaA4}/${form.id}`)
      toast.success(response.data.message)
      setTimeout(() => {
        dispatch({ type: FINISH_PROFORMA_DETAIL, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: FINISH_PROFORMA_DETAIL, payload: { loading: false, crud: false } })
      toast.error('Error inesperado. No se pudo finalizar los items de la proforma')
      console.log(error)
    })
  }
}

export function deleteProforma(form) {
  const request = axios.post(
    `${API_URL}/api/proforma/eliminar-proforma`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PROFORMA, payload: { loading: true } })
    toast.info('Eliminando proforma...', {
      toastId: 'deleteProforma',
      autoClose: false,
    })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_PROFORMA,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje, {
            toastId: 'deleteProforma',
            autoClose: 5000,
          })
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'deleteProforma',
            autoClose: 5000,
          })
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PROFORMA,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PROFORMA,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado, no se pudo eliminar la proforma', {
          toastId: 'deleteProforma',
        })
        console.log(error)
      })
  }
}

export function sendProformaByMail(form) {
  const request = axios.post(
    `${API_URL}/api/enviaproformacorreo`,
    form
  )
  return (dispatch) => {
    toast.info('Enviando comprobante por correo...', {
      toastId: 'mssgCpe',
      autoClose: false,
    })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (response.data.status == 200) {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          })
        } else {
          console.log(response.data.detalles)
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        })
      })
  }
}
