/**
 * @param {string} inputName - El valor del atributo name del elemento de entrada que se desea enfocar.
 * @param {boolean} preventScroll - Si se desea evitar el scroll hacia el elemento.
 * @return {void}
 */
export const focusInputName = (inputName = '', preventScroll = false) => {
  if (!inputName) return
  const inputs = document.getElementsByName(inputName)

  if (inputs.length > 0) {
    const input = inputs[0]
    input.focus({ preventScroll })
  }
}

/**
 * @param {string} inputId - El valor del atributo id del elemento de entrada que se desea enfocar.
 * @param {boolean} preventScroll - Si se desea evitar el scroll hacia el elemento.
 * @return {void}
 */
export const focusInputId = (inputId = '', preventScroll = false) => {
  if (!inputId) return
  const input = document.getElementById(inputId)

  if (input) input.focus({ preventScroll })
}