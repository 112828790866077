import * as Actions from "../../actions/app"

const initialState = {
  loading_save_serie: false,
  saved_serie: false,

  loading_update_serie: false,
  updated_serie: false,

  loading_delete_serie: false,
  deleted_serie: false,

  loading_series_product: false,
  series_product: [],

  loading_series_product_related: false,
  series_product_related: null,
  series_actives_product_related: 0,

  loading_series_related: false,
  series_related: null,
}

const seriesProductReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_SERIE_PRODUCT: {
      return {
        ...state,
        loading_save_serie: action.payload.loading,
        saved_serie: action.payload.saved
      }
    }

    case Actions.UPDATE_SERIE_PRODUCT: {
      return {
        ...state,
        loading_update_serie: action.payload.loading,
        updated_serie: action.payload.updated
      }
    }

    case Actions.DELETE_SERIE_PRODUCT: {
      return {
        ...state,
        loading_delete_serie: action.payload.loading,
        deleted_serie: action.payload.deleted
      }
    }

    case Actions.GET_SERIES_PRODUCT: {
      return {
        ...state,
        loading_series_product: action.payload.loading,
        series_product: action.payload.data
      }
    }

    case Actions.GET_SERIES_PRODUCT_RELATED: {
      return {
        ...state,
        loading_series_product_related: action.payload.loading,
        series_product_related: action.payload.data,
        series_actives_product_related: action.payload.series_actives,
      }
    }

    case Actions.GET_SERIES_RELATED: {
      return {
        ...state,
        loading_series_related: action.payload.loading,
        series_related: action.payload.data
      }
    }

    default: {
      return state
    }
  }
}
export default seriesProductReducer
