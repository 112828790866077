import * as Actions from '../../actions/app'

const initialState = {
  loading_cpe_action: false,
  cpe_action: false,
  cpe_action_id: undefined,

  loading_error_cpe: false,
  error_cpe: {
    ventas: [],
    guias: [],
    notas: []
  },
}

const manageCpeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CPE_ACTION: {
      return {
        ...state,
        loading_cpe_action: action.payload.loading,
        cpe_action: action.payload.action,
        cpe_action_id: action.payload.id,
      }
    }

    case Actions.ERROR_CPE: {
      return {
        ...state,
        loading_error_cpe: action.payload.loading,
        error_cpe: action.payload.data ?? state.error_cpe
      }
    }

    default: {
      return state
    }
  }
}
export default manageCpeReducer
